const queryTemplate = {
  '... on SubscriptionForm': [
    'title',
    'description',
    'listId',
    'buttonText',
    'backgroundColor',
    'textColor',
    'buttonColor',
    'buttonTextColor',
    'buttonBorderColor',
    'buttonOnHoverColor',
    'buttonOnHoverTextColor',
    'buttonOnHoverBorderColor',
    { textFont: ['family'] },
  ],
};

export default queryTemplate;
