const timelineQueryTemplate = {
  '... on TimelineItem': ['id', 'title', 'content', 'box_background_color'],
};

const timelinesQueryTemplate = {
  '... on Timeline': [
    'id',
    'title',
    {
      items: ['id', { item: [timelineQueryTemplate] }],
    },
    'background_color',
  ],
};

export default timelinesQueryTemplate;
