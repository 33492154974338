//TODO: How can we lazy load these?
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import find from 'lodash/find';
import { useRef, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useQueryAdData } from 'utils/ad-data-context';
import { FunnelDataProvider } from 'utils/funnel-data-context';
import { PreviewDataProvider } from 'utils/preview-data-context';
import { ThemeProvider } from 'utils/theme-context';
import { PAGE_TYPES, useFunnelDetail, usePageView } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';
import { propTypes, defaultProps } from 'page-props/funnel-page';
import StickyButton from 'generic/StickyButton';
import Footer from 'generic/Footer';
import GlobalStyle from 'generic/GlobalStyle';
import Sections from 'generic/Sections';
import { getDirectusFileUrl } from 'utils/directus';
import DynamicHead from 'components/DynamicHead';
import DynamicBody from 'components/DynamicBody';
import DynamicBottom from 'components/DynamicBottom';
import PreviewModeBanner from 'components/PreviewModeBanner';
import bodySectionStyles from './sales-page.module.css';
import { buildPageDataQuery } from 'utils/directus';
import { FunnelPageType } from 'constant';

import { queryTemplate as countdownBannerQueryTemplate } from 'generic/CountdownBanner';
import { queryTemplate as multiVariantBundleQueryTemplate } from 'generic/MultiVariantBundle';
import { queryTemplate as miniCartQueryTemplate } from 'generic/MiniCart';
import { queryTemplate as videoQueryTemplate } from 'generic/Video';
import { queryTemplate as multiBundleQueryTemplate } from 'generic/MultiBundle';
import { queryTemplate as accordionQueryTemplate } from 'generic/Accordion';
import { queryTemplate as autoplayVideoQueryTemplate } from 'generic/AutoplayVideo';
import { queryTemplate as comparisonQueryTemplate } from 'generic/Comparison';
import { queryTemplate as featuredInQueryTemplate } from 'generic/FeaturedIn';
import { queryTemplate as guaranteeQueryTemplate } from 'generic/Guarantee.theme1';
import { queryTemplate as heroQueryTemplate } from 'generic/Hero';
import { queryTemplate as multiProductQueryTemplate } from 'generic/MultiProduct';
import { queryTemplate as storyQueryTemplate } from 'generic/Story';
import { queryTemplate as valuePropositionQueryTemplate } from 'generic/ValueProposition.theme1';
import { queryTemplate as detailListQueryTemplate } from 'generic/DetailList';
import { queryTemplate as testimonialQueryTemplate } from 'generic/Testimonial.theme1';
import { queryTemplate as twoColumnQueryTemplate } from 'generic/TwoColumn';
import timelinesQueryTemplate from 'generic/query-templates/timelinesQueryTemplate';

import {
  headerLogoQueryTemplate,
  navBarQueryTemplate,
  stickyBannerQueryTemplate,
  logosBannerQueryTemplate,
  interactQuizQueryTemplate,
  stickyButtonAnchorQueryTemplate,
  showStickyButtonQueryTemplate,
  bylineQueryTemplate,
  headingImageTextQueryTemplate,
  imageOnlyQueryTemplate,
  stickyButtonQueryTemplate,
  photoScrollerBannerQueryTemplate,
  mediaTextBlockQueryTemplate,
  finalCtaQueryTemplate,
} from 'utils/component-queries';
import bannerQueryTemplate from 'generic/query-templates/bannerQueryTemplate';
import contentModuleQueryTemplate from 'generic/query-templates/contentModuleQueryTemplate';
import footerQueryTemplate from 'generic/query-templates/footerQueryTemplate';
import { queryTemplate as globalStyleQueryTemplate } from 'generic/GlobalStyle';
import { StickyAnchorProvider } from 'utils/sticky-anchor-context';
import { CartProvider } from 'utils/cart-context';
import { buildCssVar } from 'utils/style-override';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

let stripePromise;
const getStripe = async (chargeType, stripeAccount) => {
  if (!stripePromise) {
    const options = {};

    if (chargeType === 'DirectCharge') {
      options.stripeAccount = stripeAccount;
    }

    await sleep(process.env.NEXT_PUBLIC_STRIPE_DELAY || 0);
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY, options);
  }

  return stripePromise;
};

const collectionsUsingStripe = ['FunnelCart'];
export function needsStripeContext(pageData) {
  return pageData.sections.some(section => {
    if (collectionsUsingStripe.includes(section.collection)) {
      return true;
    }

    if (section.collection === 'TwoColumn') {
      if (section.item.rightColumn.some(el => collectionsUsingStripe.includes(el.collection))) {
        return true;
      }
    }

    return false;
  });
}

export const buildPage = () => {
  function SalesPage({ preview, funnelData, pageData }) {
    useQueryAdData();

    const { merchantName, offerData, isAfterpaySupported } = funnelData;

    const {
      title,
      favIcon,
      sections,
      banners,
      stickyButton,
      footer,
      theme,
      backgroundColor,
      ...styles
    } = pageData;

    const favIconSrc = useMemo(() => getDirectusFileUrl(favIcon), [favIcon]);

    const { asPath } = useRouter();
    usePageView({ pageType: PAGE_TYPES.SALES_PAGE, path: asPath, merchantName });
    useFunnelDetail(offerData);

    rudder.usePageView({ pageType: PAGE_TYPES.SALES_PAGE, path: asPath, merchantName, funnelData });
    rudder.useFunnelDetail(offerData);

    const showStickyButtonRef = useRef();
    const stickyRef = useRef();

    const showStickyButton = useMemo(() => {
      const isShowStickyButtonFound = sections.some(el => el.collection === 'ShowStickyButton');
      const isStickyButtonAnchorFound = sections.some(el => el.collection === 'StickyButtonAnchor');

      return stickyButton && isShowStickyButtonFound && isStickyButtonAnchorFound;
    }, [sections, stickyButton]);

    useEffect(() => {
      const handleStickyBtn = () => {
        if (!stickyRef.current) return;

        if (showStickyButtonRef.current?.getBoundingClientRect().top < window.innerHeight) {
          stickyRef.current.style = 'bottom: 0;';
        } else {
          stickyRef.current.style = 'bottom: -130px;';
        }
      };

      window.addEventListener('scroll', handleStickyBtn, { passive: true });

      return function cleanup() {
        window.removeEventListener('scroll', handleStickyBtn);
      };
    }, []);

    const [anchorRef, setAnchorRef] = useState();
    const stickyAnchorValue = { setAnchorRef, anchorRef };

    let metaDescription;
    sections.forEach(section => {
      if (section.item) {
        const headingText = find(section.item.rightColumn, { collection: 'HeadingText' })?.item
          ?.heading;
        if (headingText) {
          metaDescription = headingText;
        }
      }
    });

    const pageComponent = (
      <ThemeProvider theme={theme}>
        <PreviewDataProvider preview={preview}>
          <FunnelDataProvider funnelData={funnelData}>
            <CartProvider>
              {preview && <PreviewModeBanner />}
              <DynamicBody></DynamicBody>
              <div>
                <GlobalStyle fonts={funnelData.fonts} {...styles} />
                <DynamicHead metaDescription={metaDescription}></DynamicHead>
                <Head>
                  <title>{title}</title>
                  <meta property="og:title" content={title}></meta>
                  <meta name="twitter:title" content={title}></meta>
                  <link rel="icon" href={favIconSrc} />
                </Head>
                <StickyAnchorProvider stickyAnchorElement={stickyAnchorValue}>
                  {isAfterpaySupported && (
                    <Script
                      src="https://js.afterpay.com/afterpay-1.x.js"
                      data-analytics-enabled
                      strategy="lazyOnload"
                    ></Script>
                  )}
                  <main>
                    {banners && <Sections sections={banners} />}
                    <div className={`root ${bodySectionStyles.body_section_container}`}>
                      <style jsx>
                        {`
                          .root {
                            ${buildCssVar(
                              '--background-color',
                              backgroundColor,
                              'var(--default-background-color)'
                            )}
                          }
                        `}
                      </style>

                      <Sections sections={sections} showStickyButtonRef={showStickyButtonRef} />
                    </div>

                    {showStickyButton && <StickyButton buttonRef={stickyRef} {...stickyButton} />}
                  </main>
                </StickyAnchorProvider>
                <Footer {...footer} footerLinks={funnelData.links} />
              </div>
              <DynamicBottom></DynamicBottom>
            </CartProvider>
          </FunnelDataProvider>
        </PreviewDataProvider>
      </ThemeProvider>
    );

    if (needsStripeContext(pageData)) {
      const { offersConfig } = funnelData;
      const store = offersConfig?.[0]?.store;

      return (
        <Elements stripe={getStripe(store?.chargeType, store?.connectedStripeAccountId)}>
          {pageComponent}
        </Elements>
      );
    } else {
      return pageComponent;
    }
  }

  SalesPage.propTypes = propTypes;
  SalesPage.defaultProps = defaultProps;

  return SalesPage;
};

const salesPageQueryTemplateFields = [
  'id',
  'title',
  {
    favIcon: ['...FileParts'],
  },
  {
    banners: [
      'id',
      'collection',
      {
        item: [
          bannerQueryTemplate,
          headerLogoQueryTemplate,
          countdownBannerQueryTemplate,
          navBarQueryTemplate,
          stickyBannerQueryTemplate,
          photoScrollerBannerQueryTemplate,
        ],
      },
    ],
  },
  {
    sections: [
      'id',
      'collection',
      {
        item: [
          logosBannerQueryTemplate,
          interactQuizQueryTemplate,
          stickyButtonAnchorQueryTemplate,
          showStickyButtonQueryTemplate,
          multiVariantBundleQueryTemplate,
          miniCartQueryTemplate,
          videoQueryTemplate,
          multiBundleQueryTemplate,
          accordionQueryTemplate,
          autoplayVideoQueryTemplate,
          comparisonQueryTemplate,
          featuredInQueryTemplate,
          guaranteeQueryTemplate,
          heroQueryTemplate,
          multiProductQueryTemplate,
          storyQueryTemplate,
          testimonialQueryTemplate,
          valuePropositionQueryTemplate,
          detailListQueryTemplate,
          bylineQueryTemplate,
          headingImageTextQueryTemplate,
          imageOnlyQueryTemplate,
          twoColumnQueryTemplate,
          photoScrollerBannerQueryTemplate,
          contentModuleQueryTemplate,
          mediaTextBlockQueryTemplate,
          finalCtaQueryTemplate,
          timelinesQueryTemplate,
        ],
      },
    ],
  },
  'theme',
  stickyButtonQueryTemplate,
  footerQueryTemplate,
  ...globalStyleQueryTemplate,
];

export const pageDataQuery = buildPageDataQuery(
  FunnelPageType.SALES_PAGE,
  salesPageQueryTemplateFields
);
