import { FunnelPageType } from 'constant';
import { buildFullyDynamicGetStaticProps, buildGetStaticPaths } from 'page-props/funnel-page';
import { buildPage, pageDataQuery } from 'page-builders/sales-page';

const getStaticProps = buildFullyDynamicGetStaticProps({
  pageDataQuery,
  pageType: FunnelPageType.SALES_PAGE,
});

const getStaticPaths = buildGetStaticPaths({ funnelPageType: FunnelPageType.SALES_PAGE });

export { getStaticProps, getStaticPaths };

export default buildPage();
