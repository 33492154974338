import Head from 'next/head';
import { useFunnelData } from 'utils/funnel-data-context';

export default function DynamicHead({ metaDescription }) {
  const { canonicalUrl, fontLoadQueryString } = useFunnelData();

  return (
    <Head>
      {/* Google Fonts */}
      {metaDescription && <meta name="description" content={metaDescription}></meta>}
      {process.env.NEXT_PUBLIC_USE_SYSTEM_FONTS !== 'true' && fontLoadQueryString && (
        <>
          {/* https://pagespeedchecklist.com/asynchronous-google-fonts */}
          <link
            rel="preload"
            as="style"
            onLoad="this.rel='stylesheet'"
            href={`https://fonts.googleapis.com/css2?family=${fontLoadQueryString}&display=swap`}
          />
          <noscript>
            <link
              rel="stylesheet"
              href={`https://fonts.googleapis.com/css2?family=${fontLoadQueryString}&display=swap`}
            />
          </noscript>
        </>
      )}

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Head>
  );
}
