import subscriptionFormQueryTemplate from './subscriptionformQueryTemplate';

const queryTemplate = {
  footer: [
    { logo: ['...FilePartsWithMetadata'] },
    'brandName',
    'externalPrivacyPolicyLink',
    'externalTermsAndConditionsLink',
    'externalRefundsLink',
    'externalContactUsLink',
    'textColor',
    'backgroundColor',
    'linkHoverColor',
    { textFont: ['family'] },
    { subscription: [...Object.values(subscriptionFormQueryTemplate)[0]] },
  ],
};

export default queryTemplate;
