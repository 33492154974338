const queryTemplate = {
  '... on ContentModule': [
    'sectionId',
    'heading',
    { image: ['...FilePartsWithMetadata'] },
    { placeholderImage: ['...FilePartsWithMetadata'] },
    { video: ['...FileParts'] },
    'mediaAlt',
    'text',
    'headingColor',
    'textColor',
    'backgroundColor',
    'fontSpacing',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

export default queryTemplate;
